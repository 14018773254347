import * as React from 'react';

const ArtificialIntelligence = () => {
  return (
    <div className=" mt-32 max-w-5xl mx-auto relative leading-relaxed px-4">
      <h1 className="font-display text-5xl leading-normal font-semibold">
        Artificial Intelligence Services by Zegates
      </h1>
      <div className="w-full">
        <div className="border-default border-2 inline-block rounded-full px-8 bg-lightgreen my-4 py-1 font-semibold">
          Zegates Services
        </div>
      </div>

      <div className="grid sm:grid-cols-2 my-24 gap-4 ">
        <img src="/images/services/ai-main.jpg" className=" rounded-2xl" />

        <div className="flex items-center justify-center">
          <p className="sm:max-w-96">
            At Zegates, we leverage cutting-edge artificial intelligence (AI)
            technologies to help businesses innovate, automate, and achieve
            exceptional results. Our AI solutions are designed to meet the
            unique needs of our clients across various industries, providing
            them with a competitive edge in the market. Here’s how Zegates can
            transform your business with our AI services:
          </p>
        </div>
      </div>
      <h1 className="font-display text-5xl leading-normal font-semibold text-center my-16">
        Custom AI Development
      </h1>

      <div className="grid sm:grid-cols-2 mt-8 gap-4">
        <div>
          <p className="mb-8 sm:max-w-96">
            We specialize in developing tailor-made AI solutions that address
            specific business challenges. Our team of experts works closely with
            clients to understand their unique requirements and deliver AI
            applications that integrate seamlessly with their existing systems.
          </p>
          <div className="flex justify-center sm:justify-start">
            <img
              src="/images/services/ai-custom.jpg"
              className=" rounded-2xl"
            />
          </div>
        </div>

        <div className="">
          <h3 className="text-lg my-4 font-bold">Machine Learning Models</h3>

          <p className="mb-4">
            We design and implement machine learning models that can predict
            trends, automate decision-making, and optimize processes. Computer
          </p>

          <h3 className="text-lg my-2 font-bold">
            Natural Language Processing (NLP)
          </h3>

          <p className="mb-4">
            Our NLP solutions enable businesses to process and analyze large
            volumes of text data, enhance customer service with chatbots, and
            gain insights from unstructured data.
          </p>
        </div>
      </div>

      <h1 className="font-display text-5xl leading-normal font-semibold text-center my-16">
        AI-Powered Analytics
      </h1>

      <div className="grid sm:grid-cols-2 mt-8 gap-12 mb-16">
        <div className="">
          <h3 className="text-lg my-4 font-bold">Predictive Analytics</h3>

          <p className="mb-4">
            Our predictive analytics tools forecast future trends based on
            historical data, helping businesses stay ahead of the curve.
          </p>

          <h3 className="text-lg my-2 font-bold">Descriptive Analytics</h3>

          <p className="mb-4">
            We provide comprehensive data analysis to understand past
            performance and identify patterns and anomalies.
          </p>

          <h3 className="text-lg my-2 font-bold">Prescriptive Analytics</h3>

          <p className="mb-4">
            Our solutions recommend actions based on data analysis, optimizing
            business operations and improving outcomes.
          </p>
        </div>
        <div>
          <p className="mb-8 max-w-96">
            Harness the power of data with our AI-driven analytics solutions. We
            help businesses make sense of their data and derive actionable
            insights that drive strategic decisions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArtificialIntelligence;
