import * as React from 'react';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import ArtificialIntelligence from '../../modules/serviceinfo/ArtificialIntelligence';

const IndexPage = () => {
  return (
    <Layout>
      <ArtificialIntelligence />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
